import przemek from '@images/workers/przemek.webp';
import { phoneNumberPrzemek } from '@utils/variables';

const nbsp = '\u00A0';

export const pageData = {
  id: 1,
  title: `Projekty, granty, dotacje`,
  // subtitle: `Prowadzimy szkolenia i procesy HR dla naszych Klientów:`,
  slug: `projekty-granty-dotacje`,
  worker: {
    employee: `Przemysław Pruszyński`,
    employeePhone: `${phoneNumberPrzemek}`,
    employeePhoto: przemek,
  },
  description: {
    content: `Dzięki współpracy z naszą firmą podmioty gospodarcze mogą pozyskać granty i dotacje na rozwój. Ponadto pomagamy naszym Klientom w${nbsp}realizacji prowadzonych przez Nich procesów inwestycyjnych.`,
    list: [
      `Oferujemy:`,
      `przygotowanie planów inwestycyjnych`,
      `przygotowanie biznesplanów i projekcji finansowych`,
      `prowadzenie całego procesu inwestycyjnego - nadzór organizacyjny, finansowy i wykonawczy`,
      `przygotowanie projektów dotacyjnych i grantowych oraz ich prowadzenie i rozliczanie`,
    ],
  },
};

export default {};
