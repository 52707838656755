import { pageData } from '@utils/subpages-data/projekty-granty-dotacje';
import React from 'react';

import Subpage from './_subpage';

const Mergers = () => {
  return <Subpage data={pageData} />;
};

export default Mergers;
